import { motion } from "framer-motion";
import React, { ReactNode, useEffect } from "react";
import { cn } from "@mindstonehq/ui";
import { classNames } from "~/utils";

export default function ProgressCircle({
  completion,
  size,
  children,
  circleClass = "stroke-success",
  className,
}: {
  completion: number;
  size: number;
  className?: string;
  children?: ReactNode;
  circleClass?: string;
}) {
  const percentage = completion / 100;
  const rotation = -90; // needed so cricle path starts where we want it
  const ringWidth = 3;
  const innerWidth = 50 - ringWidth;

  const startDelay = 0.01;
  const animatedCirclePathDuration = 1.5;
  const animatedCircleOpacityDuration = 0.5;
  const greyAndTextDelay = startDelay + animatedCirclePathDuration - 1;

  const drawAnimatedCircle = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: {
      pathLength: percentage > 1 ? 1 : percentage,
      opacity: percentage === 0 ? 0 : 1,
      transition: {
        pathLength: {
          type: "spring",
          duration: animatedCircleOpacityDuration,
          bounce: 0,
          delay: startDelay,
        },
        opacity: { duration: animatedCircleOpacityDuration, delay: startDelay },
      },
    },
  };

  const drawGrey = {
    hidden: { pathLength: 1, opacity: 0 },
    visible: {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: {
          type: "spring",
          duration: 2.5,
          bounce: 0,
          delay: greyAndTextDelay,
        },
        opacity: { duration: 1, delay: greyAndTextDelay },
      },
    },
  };

  const drawText = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delay: greyAndTextDelay,
        duration: 1,
      },
    },
  };

  return (
    <div
      className={cn("overflow-hidden flex justify-center", className)}
      style={{ height: size, minHeight: size }}
    >
      <div
        className="flex relative items-center justify-center"
        style={{
          height: size,
          width: size,
        }}
      >
        {children && (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={drawText}
            className="rounded-full absolute flex flex-col items-center justify-center"
          >
            {children}
          </motion.div>
        )}
        <motion.svg
          viewBox={`0 0 50 50`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          initial="hidden"
          animate="visible"
          style={{ transform: `rotate(${rotation}deg)` }}
          className={"h-full w-full"}
        >
          {/* bg circle */}
          <motion.circle
            cx={50 / 2}
            cy={50 / 2}
            r={innerWidth / 2}
            style={{
              zIndex: 1000,
            }}
            className={classNames("opacity-10", circleClass)}
            strokeWidth={ringWidth}
            variants={drawGrey}
            strokeLinecap="round"
            custom={1}
            fill="none"
          />
          {/* animated circle */}
          <motion.circle
            key={percentage}
            cx={50 / 2}
            cy={50 / 2}
            r={innerWidth / 2}
            style={{
              zIndex: 1000,
            }}
            className={circleClass}
            strokeWidth={ringWidth}
            variants={drawAnimatedCircle}
            strokeLinecap="round"
            custom={completion}
            fill="none"
          />
        </motion.svg>
      </div>
    </div>
  );
}
